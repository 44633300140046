/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import shareImage from '../images/og-image-temp.png'
import favicon from '../images/favicon.png'

/**
 * SEO component
 *
 * @param {string} description 
 * @param {string} lang 
 * @param {array} meta 
 * @param {string} title 
 * @param {string} image
 *
 */
function SEO({ description, lang, meta, title, image }) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            descriptionEn
            author
          }
        }
      }
    `
  )

  const metaDescription = description || (lang === 'is_IS' ? site.siteMetadata.description : site.siteMetadata.descriptionEn)

  const metaImage = image ? `${image}` : 'https://student.backend.jldevelopment.is/wp-content/uploads/2020/12/Gledilegt-nytt-ar-fra-SHI.png'

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        { rel: 'shortcut icon', type: 'image/x-icon', href: `${favicon}` },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: "fb:app_id",
          content: "1047291403325727"
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: 'og:image:type',
          content: 'image/png',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)
      }
    />
  )
}

SEO.defaultProps = {
  lang: `is`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string
}

export default SEO
